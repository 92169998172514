import React, { ReactElement } from 'react';
import { getAppBrand } from '../../common/environment';

const BOLT_LOGO: ReactElement = (
  <svg
    width="132"
    height="28"
    viewBox="0 0 132 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.6182 21.2543H61.4218C67.0446 21.2543 71.5991 16.7842 71.5991 11.2738C71.5991 5.76341 67.0446 1.29325 61.4218 1.29325H54.6182V21.2543ZM59.0883 17.1497V5.39793H61.4218C64.7955 5.39793 67.0728 8.04066 67.0728 11.2738C67.0728 14.5069 64.7955 17.1497 61.4218 17.1497H59.0883Z"
      fill="#2A313C"
    />
    <path
      d="M73.568 21.2543H77.8132V14.5913C77.8132 12.567 78.8254 11.1613 80.6528 11.1613C81.3275 11.1613 81.8898 11.2738 82.5364 11.4987V7.19724C82.0023 7.05666 81.5806 7.02855 81.1588 7.02855C79.5844 7.02855 78.2631 8.06878 77.8132 9.3058V7.19724H73.568V21.2543Z"
      fill="#2A313C"
    />
    <path
      d="M86.4533 5.28547C87.9434 5.28547 89.1242 4.10467 89.1242 2.64273C89.1242 1.1808 87.9434 0 86.4533 0C85.0195 0 83.8106 1.1808 83.8106 2.64273C83.8106 4.10467 85.0195 5.28547 86.4533 5.28547ZM84.3448 21.2543H88.59V7.19724H84.3448V21.2543Z"
      fill="#2A313C"
    />
    <path
      d="M95.9107 21.2543H99.8466L106.004 7.19724H101.28L97.8505 15.7439L94.4487 7.19724H89.7537L95.9107 21.2543Z"
      fill="#2A313C"
    />
    <path
      d="M113.302 21.5636C116.254 21.5636 118.587 20.3547 119.909 18.471L116.76 16.1094C116.226 16.9247 115.017 17.7119 113.358 17.7119C111.756 17.7119 110.181 16.9247 109.928 15.2379H120.134C120.218 14.5632 120.218 14.1133 120.218 13.7478C120.218 9.19334 116.929 6.88798 113.218 6.88798C108.944 6.88798 105.824 10.0649 105.824 14.2258C105.824 18.7522 109.169 21.5636 113.302 21.5636ZM110.041 12.4546C110.406 10.9083 111.868 10.346 113.105 10.346C114.342 10.346 115.635 10.9645 116.001 12.4546H110.041Z"
      fill="#2A313C"
    />
    <path
      d="M122.331 21.2543H126.576V14.5913C126.576 12.567 127.588 11.1613 129.416 11.1613C130.09 11.1613 130.653 11.2738 131.299 11.4987V7.19724C130.765 7.05666 130.343 7.02855 129.922 7.02855C128.347 7.02855 127.026 8.06878 126.576 9.3058V7.19724H122.331V21.2543Z"
      fill="#2A313C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.942 10.3132C14.7928 7.32506 13.8708 3.40813 10.8826 1.55735C9.87982 0.938175 8.7155 0.608398 7.53773 0.608398H0V21.2026H8.47995C11.9931 21.2026 14.8399 18.3557 14.8399 14.8426C14.8399 13.1399 14.1602 11.5112 12.942 10.3132ZM4.84569 5.45408H7.53773C8.37227 5.45408 9.05201 6.13383 9.05201 6.96836C9.05201 7.8029 8.37227 8.48264 7.53773 8.48264H4.84569V5.45408ZM8.47995 16.3569H4.84569V13.3283H8.47995C9.31448 13.3283 9.99423 14.0081 9.99423 14.8426C9.99423 15.6771 9.31448 16.3569 8.47995 16.3569ZM38.2607 0.608398V21.2026H33.415V1.63811L38.2607 0.608398ZM24.1275 6.45687C20.0019 6.45687 16.6503 9.80174 16.6503 13.934C16.6503 18.0596 19.9952 21.4112 24.1275 21.4112C28.253 21.4112 31.6046 18.0663 31.6046 13.934C31.5979 9.80847 28.253 6.45687 24.1275 6.45687ZM24.1275 16.3569C22.7882 16.3569 21.7046 15.2733 21.7046 13.934C21.7046 12.5947 22.7882 11.5112 24.1275 11.5112C25.4668 11.5112 26.5503 12.5947 26.5503 13.934C26.5503 15.2733 25.4668 16.3569 24.1275 16.3569ZM26.5503 25.5771C26.5503 26.9152 25.4656 28 24.1275 28C22.7894 28 21.7046 26.9152 21.7046 25.5771C21.7046 24.239 22.7894 23.1543 24.1275 23.1543C25.4656 23.1543 26.5503 24.239 26.5503 25.5771ZM47.5483 6.73281V11.5718H45.1322V15.381C45.1322 16.5319 45.5023 17.3799 46.4715 17.3799C46.8416 17.3866 47.2051 17.3395 47.555 17.2385V20.8055C46.8282 21.2026 46.0205 21.4112 45.1927 21.4112H45.1322C45.101 21.4112 45.0698 21.4094 45.0396 21.4077C45.011 21.4061 44.9833 21.4045 44.9572 21.4045H44.9101L44.8226 21.3977C42.1171 21.2564 40.2798 19.5537 40.2798 16.5992V16.5857V16.5655V11.5785V4.06095L45.1254 3.03124V6.73281H47.5483Z"
      fill="#32BB78"
    />
  </svg>
);

const HOPP_LOGO: ReactElement = (
  <svg
    version="1.0"
    height="28"
    viewBox="188.46 1015.618 1012.753 426.057"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g
      transform="matrix(1.333333, 0, 0, -1.333333, -268.820831, 1669.577393)"
      id="g10"
    >
      <g clipPath="url(#clipPath18)" id="g14">
        <g transform="translate(665.7297,338.6431)" id="g20">
          <path
            id="path22"
            fill="#000000"
            d="M 0,0 C 0,16.5 -12.873,29.364 -29.362,29.364 -45.853,29.364 -58.727,16.49 -58.727,0 c 0,-16.49 12.864,-29.364 29.365,-29.364 C -12.862,-29.364 0,-16.49 0,0 m 57.416,0 c 0,-48.839 -37.614,-86.127 -86.79,-86.127 -49.173,0 -86.788,37.288 -86.788,86.127 0,48.838 37.615,86.127 86.788,86.127 49.176,0 86.79,-37.289 86.79,-86.127 m 317.443,0 c 0,16.5 -12.873,29.364 -29.362,29.364 -16.492,0 -29.365,-12.874 -29.365,-29.364 0,-16.49 12.865,-29.364 29.365,-29.364 16.5,0 29.362,12.874 29.362,29.364 m 59.403,0 c 0,-49.827 -32.339,-86.127 -73.591,-86.127 -15.906,0 -30.066,5.622 -40.912,16.846 v -70.633 l -57.416,-11.878 V 82.491 h 56.101 V 67.858 c 11.016,12.172 25.679,18.259 42.239,18.259 42.899,0 73.587,-37.95 73.587,-86.127 M 186.443,0 c 0,16.5 -12.873,29.364 -29.374,29.364 -16.5,0 -29.365,-12.874 -29.365,-29.364 0,-16.49 12.874,-29.364 29.365,-29.364 16.489,0 29.374,12.874 29.374,29.364 m 59.391,0 c 0,-49.827 -32.338,-86.127 -73.59,-86.127 -15.907,0 -30.066,5.622 -40.913,16.846 v -70.633 L 73.916,-151.792 V 82.491 h 56.101 V 67.858 c 11.016,12.172 25.679,18.259 42.238,18.259 42.9,0 73.588,-37.95 73.588,-86.127 m -438.558,66 h -69.962 v 85.801 H -322.73 V -82.501 h 60.053 V 8.25 h 69.953 v -90.751 h 60.062 v 234.292 h -60.062 "
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Logo: React.FC = (): ReactElement => {
  const isHopp = getAppBrand() === 'hopp';
  const logo = isHopp ? HOPP_LOGO : BOLT_LOGO;
  return <>{logo}</>;
};
